import React from "react";
import "./services.scss";
import ServiceItem from "../../SingleFeatures/service_item";
import SectionTitle from "../section_title";
import serviceIcon_01 from "../../images/icon_1.png";
import serviceIcon_02 from "../../images/icon_2.png";
import serviceIcon_03 from "../../images/icon_3.png";

export default function Index() {
  return (
    <section className="deneb_service service_v1">
      <div className="container">
        <SectionTitle
          title="Our Services"
          description="Have a look on our services."
        />
        <div className="row">
          <ServiceItem
            icon={serviceIcon_01}
            title="Web Solutions"
            url="#"
            description="We offer fast, fully functional and ecstatic web solutions to escalate any kind of business."
          />
          <ServiceItem
            icon={serviceIcon_02}
            title="Mobile Solutions"
            url="#"
            description="We develop fast, effective and reliable mobile apps for android and ios."
          />
          <ServiceItem
            icon={serviceIcon_03}
            title="IoT Solutions"
            url="#"
            description="Our experts will understand your requirement and give you the best IOT solutions for quick and effective results."
          />
        </div>
      </div>
    </section>
  );
}
