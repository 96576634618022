import React from "react";
import contactImg from "../../images/contact.png";
import { Field, Form, Formik, FormikProps } from "formik";
import emailjs from "emailjs-com";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

import "./contact.scss";
export default function Index() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "cyberkode_xHq12$#2q0",
        "cyberkode_m6pkvgg",
        e.target,
        "V4I7bImfUgNMhn-OM"
      )
      .then(
        (result) => {
          swal(
            "Thank you!",
            "We've got your message and will get back to you soon.",
            "success"
          );
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <section className="deneb_contact contact_v1" style={{marginBottom: '-150px'}}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="deneb_img_box">
              <img src={contactImg} className="img-fluid" alt="contact" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="section_title">
              <h2>Get In Touch With Us</h2>
              <p style={{paddingLeft: '20px', paddingRight: '20px'}}>
                Have you got any project in your mind. Simply send us a message.
                We'll get back to you soon.
              </p>
            </div>
            <div className="contact_form" style={{paddingLeft: '20px', paddingRight: '20px'}}>
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  email_address: "",
                  phone_number: "",
                  user_message: "",
                }}
              >
                {() => (
                  <Form onSubmit={sendEmail}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form_group">
                          <Field
                            type="text"
                            name="first_name"
                            className="form_control"
                            placeholder="First Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <Field
                            type="text"
                            name="last_name"
                            className="form_control"
                            placeholder="Last Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <Field
                            type="email"
                            name="email_address"
                            className="form_control"
                            placeholder="Email Address"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <Field
                            type="number"
                            name="phone_number"
                            className="form_control"
                            placeholder="Phone No"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form_group">
                          <Field
                            as="textarea"
                            name="user_message"
                            className="form_control"
                            placeholder="Message"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <ReCAPTCHA
                          sitekey={"6LcmcBwkAAAAAI6YZyipfOjMH6SNd_LXnzqTZy88"}
                        />
                        <div className="button_box">
                          <button
                            className="deneb_btn"
                            style={{ marginTop: "20px" }}
                          >
                            Reach Us
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
