import React from "react";
import icon_07 from "../../images/icon_7.png";
import icon_08 from "../../images/icon_8.png";
import icon_09 from "../../images/icon_9.png";
import icon_10 from "../../images/icon_10.png";
import "./projects_info.scss";

export default function Index() {
  return (
    <section className="deneb_achivement section_padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6  wow fadeInLeft" >
            <div className="section_title">
              <h2 style={{marginLeft:'20px', marginRight: '20px'}}>Over 300 Completed work & Still Counting</h2>
            </div>
            <div className="deneb_content_box">
              <p style={{marginLeft:'20px', marginRight: '20px'}}>
                We love a challenge here at <strong>CyberKode</strong>, and we
                are more than ready to take one on with you! Our collaborative
                nature means that we will work with you every step of the way to
                ensure that your project is a success. We pride ourselves on
                being able to listen to our clients and act upon their needs to
                deliver the best results. With a team full of knowledgeable and
                experienced professionals, we are confident that we can provide
                an excellent service for you.
              </p>
              <p style={{marginLeft:'20px', marginRight: '20px'}}>
                With a team full of knowledgeable and experienced professionals,
                we are confident that we can provide an excellent service for
                you.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="achivment_wrapper">
              <div className="single_achivment wow fadeInUp">
                <div className="icon">
                  <img src={icon_07} className="img-fluid" alt="" />
                </div>
                <h3>200+</h3>
                <p>Web Projects Done</p>
              </div>
              <div
                className="single_achivment wow fadeInUp"
                data-wow-delay=".1s"
              >
                <div className="icon">
                  <img src={icon_08} className="img-fluid" alt="" />
                </div>
                <h3>100+</h3>
                <p>Mobile Projects Done</p>
              </div>
              <div
                className="single_achivment wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  <img src={icon_09} className="img-fluid" alt="" />
                </div>
                <h3>10+</h3>
                <p>Active Projects</p>
              </div>
              <div
                className="single_achivment wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="icon">
                  <img src={icon_10} className="img-fluid" alt="" />
                </div>
                <h3>5+</h3>
                <p>Years Experience</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
