import React from "react";
import CallToAction from "../call_to_action";
import footerImg from "../../images/footer_bg.png";
import logoImg from "../../images/logo_1.png";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaLinkedin,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./footer.scss";

export default function Index() {
  const scrollToTop = (e) => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <>
      <CallToAction />
      <footer className="deneb_footer">
        <div
          className="widget_wrapper"
          style={{ backgroundImage: `url(${footerImg})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="widget widegt_about">
                  <div className="widget_title">
                    <Link to="/" onClick={scrollToTop}>
                      <img
                        src={logoImg}
                        style={{ width: "250px" }}
                        className="img-fluid"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <p>
                    <strong>CyberKode</strong> is a tech startup founded in
                    2018. We are expertise in Robust Web Solutions, Mobile
                    Application Development and IoT Solutions.
                  </p>
                  <ul className="social">
                    <li>
                      <a href="https://www.facebook.com/cyberkode">
                        <i>
                          <FaFacebookF />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/cyber_kode">
                        <i>
                          <FaTwitter />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/cyberkode/">
                        <i>
                          <FaLinkedin />
                        </i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/*  <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget widget_link">
                                    <div className="widget_title">
                                        <h4>Links</h4>
                                    </div>
                                    <ul>
                                        <li><Link to="/about" onClick={scrollToTop}>About Us</Link></li>
                                        <li><Link to="/services" onClick={scrollToTop}>Services</Link></li>
                                        <li><Link to="/portfolios" onClick={scrollToTop}>Portfolios</Link></li>
                                        <li><Link to="/blog" onClick={scrollToTop}>Portfolios</Link></li>
                                        <li><Link to="/contact" onClick={scrollToTop}>Contact Us</Link></li>
                                    </ul> 
    </div> 
    </div>*/}
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="widget widget_contact">
                  <div className="widget_title">
                    <h4>Contact Us</h4>
                  </div>
                  <div className="contact_info">
                    <div className="single_info">
                      <div className="icon">
                        <i>
                          <FaPhoneAlt />
                        </i>
                      </div>
                      <div className="info">
                        <p>
                          <a href="tel:+61420379981">0420379981</a>
                        </p>
                        <p>
                          <a href="tel:+61883328158">08-8332-8158</a>
                        </p>
                      </div>
                    </div>
                    <div className="single_info">
                      <div className="icon">
                        <i>
                          <FaEnvelope />
                        </i>
                      </div>
                      <div className="info">
                        <p>
                          <a href="mailto:hello@cyberkode.com.au">
                            hello@cyberkode.com.au
                          </a>
                        </p>
                        <p>
                          <a href="mailto:pinidu@cyberkode.com.au">
                            pinidu@cyberkode.com.au
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="single_info">
                      <div className="icon">
                        <i>
                          <FaMapMarkerAlt />
                        </i>
                      </div>
                      <div className="info">
                        <p>
                          29 Grant Avenue<span>Gilles Plains 5086</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright_area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright_text">
                  <p>
                    Copyright &copy; 2022 <span>Cyberkode PTY LTD</span>. All
                    rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
