import React from 'react';
import aboutImg from "../../images/about.png";
import awardImg from "../../images/award.png";
import signImg from "../../images/sign_2.png";

export default function HireUs() {
    return (
        <section className="deneb_about about_v2">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="deneb_img_box">
                            <img src={aboutImg} className="img-fluid" alt="about"/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="deneb_content_box">
                            <h2>Why You Hire Us?</h2>
                            <p>We have maintained excellent relationships with our clients, and it’s because we’re much concerned about setting the right outcomes, and then we strive to meet or exceed them</p>
                            <p>
                            <strong>CyberKode</strong> is currently a team of 12, but family is in the core of it all, and that’s why most of our employees have been with us for many years. Behind <strong>CyberKode</strong> there are 2 siblings that were born and raised in Sri Lanka. Pini leads strategy and operations in Adelaide(Australia) and Dulli is the lead of organization in Sri Lanka. 
                            </p>
                        </div>
                        <div className="award_box">
                            <div className="award_img">
                                <img src={awardImg} alt="award"/>
                            </div>
                            <div className="award_info">
                                <img src={signImg} className="img-fluid" alt="signin"/>
                                <h4>Deneb Team</h4>
                                <h5>Best Award 2020</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
