import React from "react";
import aboutImg from "../../images/about.png";
import { Link } from "react-router-dom";
import "./about.scss";

export default function Index() {
  const scrollToTop = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className="deneb_about about_v1">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="deneb_img_box wow fadeInLeft">
              <img src={aboutImg} className="img-fluid" alt="about" />
            </div>
          </div>
          <div className="col-lg-7" style={{paddingLeft: '20px', paddingRight: '20px'}}>
            <div className="deneb_content_box wow fadeInRight">
              <h2>Why You Hire Us?</h2>
              <p>
                We have maintained excellent relationships with our clients, and
                it’s because we’re much concerned about setting the right
                outcomes, and then we strive to meet or exceed them.
              </p>
              <p>
                <strong>CyberKode</strong> is currently a team of 12, but family
                is in the core of it all, and that’s why most of our employees
                have been with us for many years. Behind{" "}
                <strong>CyberKode</strong> there are 2 siblings that were born
                and raised in Sri Lanka. Pini leads strategy and operations in
                Adelaide(Australia) and Dulli is the lead of organization in Sri
                Lanka.
              </p>
              {/*<Link to="/about" onClick={scrollToTop} className="deneb_btn">Read More</Link>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
